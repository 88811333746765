<template>
    <el-container>
      <el-header class="home-head">
        <Head/>
      </el-header>
      <el-main class="home-main">
        <Content/>
      </el-main>
    </el-container>
</template>
<script>
import Head from '../video/head.vue'
import Content from '../video/content.vue'
export default{
  name:'mainPage',
  components:{Head,Content},
  data() {
    return {
      userInfo:{},

    }
  },
  created() {
     this.userInfo=JSON.parse(sessionStorage.getItem('user'))
  },
}
</script>
<style scoped>
.home-head{
  padding: 0;
  margin-bottom: 20px;
}
.home-main{
  width: 1280px;
  margin: 0 auto;
  height: 100%;
  padding: 20px 0;
}
.home-aside{
  width: 220px;
  background-color: #fff;
  border-radius: 5px;
  height: 100%;
}
.el-container {
  height: 100%;
}
</style>