<template>
  <div class="login">
    <div class="login-center">
      <div class="left">
        <img src="../assets/img/login.png" alt="">
        <img src="../assets/img/yilogo-w.png" alt="" class="yi-logo">
      </div>
      <div class="right">
        <div class="logo">
          <img src="../assets/img/yilogo.png" alt="">
        </div>
        <el-form label-position="top" :model="formLogin" class="form-login">
          <el-form-item label="用户名">
            <el-input v-model="formLogin.username" @keyup.enter.native="loginHandle"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input type="password" v-model="formLogin.password" @keyup.enter.native="loginHandle"></el-input>
          </el-form-item>
        </el-form>
        <el-button class="login-btn" @click="loginHandle">登录后台</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoginPage',
  data() {
    return {
      formLogin: {
        username: '',
        password: '',
      },
      isLogin:'',
      uid:0,
    }
  },
  methods: {
    loginHandle(){
      if(!this.formLogin) return
      if(this.formLogin.username == '') return this.$message({message:'请输入用户名',type:'error',center:true})
      if(this.formLogin.password == '') return this.$message({message:'请输入密码',type:'error',center:true})
      this.$post('/user/login',{username:this.formLogin.username,password:this.formLogin.password, }).then(res=>{
        if(res.code==200){
          this.$message({
            message: '登录成功',
            type: 'success',
            offset:'40',
            center:true,
          });
          sessionStorage.setItem('uid',res.data.uid)
          let that=this
          setTimeout(function(){
            that.$router.push({
              name:'adminMatrix'
            })
          },1000)
        }else{
          this.$message({
            message:res.msg,
            type: 'error',
            offset:'40',
            center:true,
          });
        }
      })
    },
    login(){
      const code=this.$route.query.code;
        if(code){
        this.$router.push({
          name:'adminMatrix'
        })
      }
    }
  },
  mounted() {
    this.login()
  },
}
</script>
<style scoped>
.login{
  width: 100vw;
  height: 100vh;
  background-image: url(../assets/img/login-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.login-center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  width: 800px;
}
.login-center .left{
  width: 50%;
  position: relative;
}
.login-center .left .yi-logo{
  position: absolute;
  left: 20px;
  bottom: 20px;
}
.login-center .left img:nth-child(1){
  width: 100%;
}
.login-center .right{
  width: 50%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 6% 4%;
  padding-right: 8%;
}
.login-center .right .logo{
  width: 180px;
}
.login-center .right .logo img{
  width: 100%;
}
.login-btn{
  width: 160px;
  height: 42px;
  border-radius: 5px;
  background-color: #02305e;
  color: #fff;
  font-size: 14px;
}
.form-login{
  margin: 10% 0;
}
.form-login>>> .el-form-item__label{
  color: #95a3b2;
  font-size: 14px;
  margin: 0;
  padding: 0;
}
.form-login>>> .el-form-item {
  margin-bottom: 10px;
}
</style>