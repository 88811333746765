import Vue from 'vue'
import App from './App.vue'

// element 组件库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import {get, post, download } from "./utils/request";
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$download = download;
import router from './router/index';

import * as echarts from 'echarts';
//vue全局注入echarts
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;

new Vue({
  el:'#app',
  router,
  render:h=>h(App),
  components:{App},
}).$mount("#app")
