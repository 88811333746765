<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'App'
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
#app{
  background-color: #f7f8f9;
  width: 100vw;
  height: 100vh;
}
</style>
