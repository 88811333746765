<template>
  <div class="head">
    <div class="head-content">
      <div class="logo">
        <img src="@/assets/img/yilogo.png" alt="">
      </div>
      <div class="right">
        <div class="search">
          <el-input v-model="search" placeholder="请输入内容..." prefix-icon="el-icon-search"
            @change="searchHandle"></el-input>
        </div>
        <div class="info">
          <div class="photo">
            <!-- <img :src="userInfo.head" alt=""> -->
          </div>
          <div class="text">
            <p class="user-name">{{userName}}<span>&nbsp;,&nbsp;{{tag}}</span></p>
            <p class="com-name">杭州思亿欧网络科技股份有限公司</p>
          </div>
          <div class="log-out">
            <el-button type="text" @click="logOutHandle">退出登录</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { getUid } from "../utils/token.js";
  export default {
    name: 'HeadPage',
    data() {
      return {
        search: '',
        userName:'耿佳',
        tag:'研发二部'
      }
    },
    methods: {
      // 搜索
      searchHandle() {
      },
      logOutHandle() {
        let uid = getUid('uid')
        this.$get('/account/unbind', { account_id: uid }).then(res => {
          if (res.data.code == '200') {
            sessionStorage.removeItem('uid')
            this.$message({
              message: '退出成功',
              type: 'success',
              offset: '40',
              center: true,
            });
            let that = this
            setTimeout(function () {
              that.$router.push({
                name: 'Login'
              })
            }, 1000)
          }
        })
      }
    },
  }
</script>
<style scoped>
  .head {
    background-color: #fff;
    height: 80px;
    border-radius: 5px;
    width: 100vw;
  }

  .head-content {
    max-width: 1280px;
    min-width: 800px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .head-content .logo {
    width: 164px;
    height: 58px;
  }

  .head-content .logo img {
    width: 100%;
    height: 100%;
  }

  .head-content .right {
    display: flex;
    align-items: center;
  }

  .head-content .right .info {
    display: flex;
    align-items: center;
    height: 80px;
    position: relative;
  }

  .head-content .right .info .photo {
    width: 40px;
    height: 40px;
    background-color: #2f6bff;
    border-radius: 50%;
    margin: 0 6px;
    overflow: hidden;
  }

  .head-content .right .info .photo img {
    max-width: 100%;
    border-radius: 50%;
  }

  .head-content .right .info .text .user-name {
    font-size: 16px;
    color: #171f46;
    font-weight: 500;
  }

  .head-content .right .info .text .com-name {
    font-size: 14px;
    color: #9da6ba;
    font-weight: 500;
  }

  .head-content .right .info .log-out {
    position: absolute;
    top: 80px;
    background-color: #fff;
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.1);
    display: none;
    border-radius: 5px;
    transition: all 0.2s linear;
  }

  .head-content .right .info .log-out .el-button {
    color: #171f46;
    font-size: 14px;
    transition: all 0.2s linear;
  }

  .head-content .right .info .log-out:hover {
    background-color: #2f6bff;
  }

  .head-content .right .info .log-out:hover .el-button {
    color: #fff;
  }

  .head-content .right .info:hover .log-out {
    display: flex;
  }

  .head-content .right .search {
    margin-right: 20px;
  }

  .head-content .right .search>>>.el-input .el-input__inner {
    width: 320px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #e5eaef;
    border-radius: 10px;
    color: #171f46;
  }

  .el-message {
    width: 100px;
  }
</style>