<template>
  <div class="matrix" v-loading="isLoading">
    <div class="top">
      <p class="top-title">员工矩阵</p>
      <div class="search">
        <div>
          <span class="search-title">账户名：</span>
          <el-input v-model="accountName" placeholder="请输入账户名" class="mat-sea"></el-input>
        </div>
        <div>
          <span class="search-title">授权状态：</span>
          <el-select v-model="selectStatus" placeholder="请选择" class="mat-sea">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="{value:item.value,label:item.label}">
            </el-option>
          </el-select>
        </div>
        <div>
          <el-button class="search-btn btn" icon="el-icon-search">搜索</el-button>
          <el-button class="btn empty-btn" icon="el-icon-delete" @click="emptyHandle">清空条件</el-button>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="left">
        <div class="account">
          <p class="title">员工号抖音授权</p>
          <p class="hit">
            <em class="el-icon-s-opportunity"></em>
            提示：点击图标绑定账号</p>
        </div>
        <div class="img-box">
          <div class="img" @click="notarizeAuthorization">
            <img src="../assets/img/douyin.png" alt="">
          </div>
          <span>抖音</span>
        </div>
      </div>
      <div class="right">
        <p class="title">数据统计</p>
        <div class="statistics">
          <div class="item item1">
            <div class="item-l">
              <img src="../assets/img/matrix1.png" alt="">
            </div>
            <div class="item-r">
              <p>授权账号</p>
              <p><span>{{dataStatistics.count}}</span>个</p>
            </div>
          </div>
          <span class="rule"></span>
          <div class="item item2">
            <div class="item-l">
              <img src="../assets/img/matrix2.png" alt="">

            </div>
            <div class="item-r">
              <p>过期账号</p>
              <p><span>{{dataStatistics.overCount}}</span>个</p>
            </div>
          </div>
          <span class="rule"></span>
          <div class="item item3">
            <div class="item-l">
              <img src="../assets/img/matrix3.png" alt="">

            </div>
            <div class="item-r">
              <p>视频数</p>
              <p><span>{{dataStatistics.videoNum}}</span>条</p>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="list">
      <p class="title">账户管理</p>
      <EmptyData v-if="tableData=='' " :hitValue="hitValue"/>
      <el-table
        v-else
        :data="tableData"
        style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          width="100px"
          >
        </el-table-column>
        <el-table-column
          prop="name"
          label="账户"
          >
          <template slot-scope="scope">
            <div class="account-box">
              <div>
                <img :src="scope.row.head" />
              </div>
              <span>{{scope.row.name}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="time"
          label="授权时间">
        </el-table-column>
        <el-table-column
          prop="state"
          label="授权状态">
          <template slot-scope="scope">
            <p v-if="scope.row.state=='正常' " class="circle"><i class="el-icon-circle-check"></i>&nbsp;<span>正常</span></p>
            <p v-if="scope.row.state=='异常' " class="warning"><i class="el-icon-warning-outline"></i>&nbsp;<span>异常</span></p>
          </template>
        </el-table-column>
        <el-table-column
          prop="opera"
          label="操作">
          <template slot-scope="scope">
            <el-button @click="untieHandle(scope.row)" type="text" class="untie">解绑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  -->
    <!-- <el-dialog
      class="authorization"
      :visible.sync="authorizationDialog"
      width="350px"
      :before-close="handleClose">
      <div class="img">
        <div>
          <img src="../assets/img/hit.png" alt="">
        </div>
      </div>
      <div class="hit-cen">
        <span class="hit">提示</span>
        <p>首次授权将获取到账户信息，若要发布视频 <br/> 请在列表处点击绑定按钮，扫描二维码绑定账户</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="notarizeAuthorization" class="authorization-btn">确认授权</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>
<script>
import EmptyData from "../components/emptyData.vue";
// import { getUid } from "../utils/token.js";
export default{
  name:'matrixPage',
  components:{EmptyData},
  data() {
    return {
      options: [
        {
          value: '选项1',
          label: '全部'
        }, {
          value: '选项2',
          label: '已授权'
        }, {
          value: '选项3',
          label: '未授权'
        }
      ],
      selectStatus: '',
      accountName:'',
      // 
      tableData:[],
      authorizationDialog:false,
      dataStatistics:{},
      hitValue:'暂未授权任何账号<br/>请点击上方图标进行授权',
      imgVal:'',
      isLoading:true,
      code:'',
    }
  },
  methods: {
    untieHandle(row){
      console.log(row)
    },
    handleClose(done){
      done()
    },
    emptyHandle(){
      this.selectStatus=''
      this.accountName=''
    },
    notarizeAuthorization(){
        window.location.href='https://open.douyin.com/platform/oauth/connect/?client_key=awcublr16f1qwyn4&response_type=code&scope=renew_refresh_token,user_info,following.list,data.external.fans_favourite,data.external.user,data.external.item&state=1&redirect_uri=https://dyapi.yishitui.cn/admin/matrix'
    },
    queryParams(){
      let result=null;
      const {code,scopes}=this.$route.query;
      if(code){
        result={code,scopes}
      }
      return result;
    },
    getCode(){
      var queryParams=this.queryParams();
      // let uid=getUid('uid')
      if(queryParams){
        this.$get('/account/auth_yst',{code:queryParams.code,uid:1}).then(res=>{
          if(res.code=='200'){
            this.$get('/account/list',{uid:1}).then(res=>{
              this.dataStatistics=res.data.data
              this.tableData=res.data.data.accounts
            })
          }
        })
        this.$router.replace({})
      }
   },
  },
  mounted() {
    let that=this
    setTimeout(function(){
      that.isLoading=false
    },1000)
    this.getCode()
  },
  created() {
    // let uid=getUid('uid')
    this.$get('/account/list',{uid:1}).then(res=>{
      this.dataStatistics=res.data.data
      this.tableData=res.data.data.accounts
    })
  },
  
}
</script>
<style scoped>
.top{
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
}
.top .top-title{
  color: #111634;
  font-size: 24px;
  font-weight: bold;
}
.top .search{
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.top .search>div{
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.top>>> .search .mat-sea input{
  width: 240px;
  height: 36px;
  background-color: #fff;
  border-radius: 8px;
  color: #171f46;
}
.top .search-title{
  display: block;
  color: #171f46;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}
.top .btn{
  width: 110px;
  height: 36px;
  border-radius: 8px;
  font-size: 14px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top .search-btn{
  color: #fff;
  background-color: #2f6bff;
}
.top .empty-btn{
  background-color: #fff;
  color: #171f46;
}
.info {
  width: 100%;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 120px;
}
.info .left{
  width: 30%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 20px;
}
.info .title{
  color: #333;
  font-weight: bold;
  margin-bottom: 18px;
}
.info .left .account .hit{
  font-size: 12px;
  color: #666;
  font-weight: 500;
}
.info .left .account .hit em{
  color: #2f6bff;
}
.info .left .img-box{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.info .left .img{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.info .left .img img{
  width: 100%;
  height: 100%;
}
.info .left .img-box span{
  white-space: nowrap;
  display: inline-block;
  margin-left: 10px;
  color: #171f46;
  font-weight: bold;
  font-size: 14px;
}
.info .right{
  width: 67%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
}
.info .right .statistics{
  display: flex;
  align-items: center;
}
.info .right .statistics>span{
  display: inline-block;
  width: 1px;
  height: 20px;
  background-color: #eee;
  border-radius: 1px;
  margin-right: 40px;
}
.info .right .statistics .item{
  display: flex;
  align-items: center;
  width: 33%;
}
.info .right .statistics .item .item-l{
  width: 30px;
  height: 30px;
  border-radius: 10px;
  margin-right: 10px;
  display: flex;
  align-items:center ;
  justify-content: center;
}
.info .right .statistics .item1 .item-l{
  background-color: #f0effb;
}
.info .right .statistics .item2 .item-l{
  background-color: #e9f9f3;
}
.info .right .statistics .item3 .item-l{
  background-color: #ffeef2;
}
.info .right .statistics .item .item-r p:first-child{
  font-size: 14px;
  color: #666;
  font-weight: 500;
}
.info .right .statistics .item .item-r p:last-child{
  color: #292929;
  font-size: 14px;
  font-weight: bold;
}
.info .right .statistics .item .item-r p:last-child span{
  font-size: 22px;
  display: inline-block;
  margin-right: 4px;
}
.list{
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px 0;
  height: calc(100vh - 400px);
  overflow-y: auto;
}
.list .title{
  font-weight: bold;
  color: #071140;
  margin-bottom: 20px;
  margin-left: 20px;
}
.list .circle{
  color: #008103;
}
.list .warning{
  color: #ff0000;
}
.list>>> .el-table thead {
  color: #8E98AF;
  font-weight: 500;
}
.list>>> .el-table .el-table__cell {
  padding: 12px 20px;
}
.list>>>  .el-table {
  color: #171f46;
}
.list>>> .el-table tbody tr td:nth-child(3){
  color: #666;
}
.list .untie{
  color: #2F6BFF;
}
.list>>> .el-table__body tr:hover > td {
    background-color: #efefef ;
}
.list .account-box{
  display: flex;
  align-items: center;
}
.list .account-box>div{
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  overflow: hidden;
}
.list>>> .account-box>span{
  flex: 1;
}
.list .account-box>div img{
  max-width: 100%;
  border-radius: 50%;
}
.authorization{
  margin-top: 18vh;
}
.authorization>>> .el-dialog{
  border-radius: 10px;
}
.authorization .hit-cen{
  text-align: center;
  color: #171f46;
}
.authorization .hit-cen .hit{
  font-size: 18px;
  font-weight: bold;
}
.authorization .hit-cen p{
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  line-height: 22px;
}
.authorization-btn{
  width: 160px;
  height: 38px;
  background: linear-gradient(0deg, #6475FB 0%, #ADB6FF 100%);
  border: none;
  border-radius: 19px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f5f7ff;
  font-size: 18px;
  margin: 0 auto;
}
.authorization>>> .el-dialog__body {
  padding: 3px 20px;
  padding-top: 25px;
}
.authorization>>> .el-dialog__headerbtn .el-dialog__close {
  color: #999;
  font-size: 20px;
}
.authorization .img{
  position: absolute;
  width: 98px;
  height: 98px;
  background-color: #fff;
  border-radius: 50%;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>