import Vue from 'vue'
import Router from 'vue-router'
import login from '../views/login.vue'
import Main from '../views/main.vue'
import onekey from "../views/onekey.vue";
import matrix from "../views/matrix.vue";
import flow from "../views/flow.vue";
import publish from "../views/publish.vue";



Vue.use(Router)

const router= new Router({
  mode:'history',
  // base: process.env.BASE_URL,
  routes:[
    {
      path:'/',
      redirect:'/login'
    },
    {
      path:'/login',
      name:'Login',
      component:login,
    },
    {
      path:'/admin',
      name:'admin',
      component:Main,
      children:[
        {
          path:'/admin/onekey',
          name:'adminOnekey',
          component:onekey
        },
        {
          path:'/admin/matrix',
          name:'adminMatrix',
          component:matrix
        },
        {
          path:'/admin/flow',
          name:'adminFlow',
          component:flow
        },
        {
          path:'/admin/publish',
          name:'adminPublish',
          component:publish
        },
        {
          path:'/admin/private',
          name:'adminPrivate',
          component:()=>import('../views/private.vue')
        },
      ]
    }
  ]
})

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});
  
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next('/404');
  } else {
    next();
  }
  // 如果访问的是登录界面则直接放行
  if (to.path === '/login') return next()
  //获取用户页面token信息
  let uid = window.sessionStorage.getItem('uid')
  //如果token数据为null则跳转到指定路径
  if (!uid) return next("/login")
  next()
});
export default router