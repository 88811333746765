<template>
  <div class="oneKey" v-loading="isLoading">
    <div class="top">
      <p class="title">企业号视频发布</p>
    </div>
    <div class="content">
      <div class="set">
        <div class="set-top">
          <p class="theme">上传视频</p>
        </div>
        <div class="center">
          <div class="left">
            <div class="item item1">
              <p>视频标题</p>
              <el-input v-model="titleValue" placeholder="写一个适合的标题，方便查找" prefix-icon="el-icon-edit"></el-input>
            </div>
            <div class="item item2">
              <p>设置封面</p>
              <div class="box">
                <div class="cover-left">
                  <div class="img">
                    <img src="../assets/img/one.png" alt="">
                  </div>
                  <p>支持完整视频封面截取</p>
                </div>
                <div class="cover-right">
                  <el-button type="text" icon="el-icon-edit">编辑封面</el-button>
                </div>

              </div>
            </div>
            <div class="item item3">
              <p>视频描述</p>
              <el-input v-model="textValue" type="textarea" placeholder="请输入视频描述"></el-input>
            </div>
          </div>
          <div class="right">
            <el-upload
              class="video-uploader"
              action="https://sns.jinlaijinwang.net/dy_fast"
              :show-file-list="false"
              :data="uploadData"
              :on-success="uploadVideoProcess"
              :before-upload="beforeUploadVideo">
              <i class="el-icon-upload"></i>
              <p class="hit">上传视频</p>
            </el-upload>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="item">
          <span>内容同步</span>
          <el-checkbox v-model="syncChecked" @change="syncCheckHandle">同步到抖音<span>（同步后获得更多的流量）</span></el-checkbox>
        </div>
        <div class="publish-set" v-if="isShow">
          <p class="title">抖音发布设置</p>
          <div>
            <el-form ref="form" :model="publishSetForm" label-width="68px" class="publish-set-form">
              <el-form-item label="选择账号">
                <el-select v-model="accountValue" placeholder="请选择" class="select-inp" @change="selectAccount">
                  <el-option
                    v-for="item in AccountOptions"
                    :key="item.accountId"
                    :label="item.name"
                    :value="{value:item.accountId,label:item.name}">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="抖音标题">
                <el-input v-model="publishSetForm.title" placeholder="写一个合适的标题，能让更多人看到" maxlength="50" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="添加位置">
                <el-input v-model="publishSetForm.location" placeholder="输入地理位置" maxlength="50" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="水印设置">
                <el-checkbox type="checkbox" v-model="publishSetForm.watermark">添加水印</el-checkbox>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <el-button class="publish-btn">发布</el-button>
    </div>
    
  </div>
</template>
<script>
export default{
  name:'oneKeyPage',
  data() {
    return {
      publishSetForm:{
        title:'',
        location:'',
        watermark:true,
      },
      seeValue:'',
      options:[
        {
          value:'1',
          label:'全部'
        }
      ],
      setTime:'',
      syncChecked:true,
      isShow:true,
      isLoading:true,
      titleValue:'',
      textValue:'',
      accountValue:'',
      AccountOptions:[],  
      mode:'',
      modeList:[],
      uploadData:{account_id:''}
    }
  },
  methods: {
    uploadVideoProcess(res){
     console.log(res) 

    },
    beforeUploadVideo(){
    },
    syncCheckHandle(){
      if(this.syncChecked){
        this.isShow=true
      }else{
        this.isShow=false
      }
    },
    selectAccount(){
      this.uploadData.account_id=this.accountValue.value
    }
  },
  mounted() {
    let that=this
    setTimeout(function(){
      that.isLoading=false
    },1000)
    this.$get('/account/simi',{uid:1}).then(res=>{
      this.AccountOptions=res.data.data
      this.accountValue=res.data.data[0].name
    })
  },
}
</script>
<style>
.oneKey{
  background-color: #fff;
  border-radius: 10px;
}
.top{
  box-sizing: border-box;
  padding: 20px 30px;
  border-bottom: 1px solid #ecf1f6;
}
.top .title{
  font-size: 20px;
  color: #171f46;
  font-weight: bold;
}
.content{
  box-sizing: border-box;
  padding: 20px 30px;
}
.set-top{
  border-bottom: 1px solid #e5e5e5;
  border-radius: 0px 1px 1px 1px;
}
.set-top .theme{
  width: 64px;
  height: 30px;
  color: #2f68ff;
  font-weight: bold;
  border-bottom: 3px solid #2f68ff;
  border-radius: 1px 2px 2px 2px;
}
.set .center{
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 0;
}
.set .center .left{
  width: 700px;
}
.set .center .left .item{
  width: 100%;
  margin-bottom: 15px;
}
.set .center .left .item>p{
  font-size: 14px;
  color: #071140;
  margin: 10px 0;
}
.set .center .left .item1 input{
  width: 100%;
  height: 50px;
  background-color: #f5f5f5;
  border-radius: 5px;
  border: none;
  color: #333;
}
.set .center .left .item2 .box{
  height: 80px;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 6px 10px;
  padding-right: 30px;
}
.set .center .left .item2 .box .cover-left{
  display: flex;
  align-items: center;
}
.set .center .left .item2 .box .cover-left>p{
  color: #333;
  font-size: 14px;
  margin-left: 10px;
}
.set .center .left .item2 .box .cover-left .img{
  width: 48px;
  height: 68px;
  border-radius: 2px;
}
.set .center .left .item2 .box .cover-left .img img{
  max-width: 100%;
  max-height: 100%;
}
.set .center .left .item2 .box .cover-right .el-button{
  color: #333;
  font-size: 14px;
}
.set .center .left .item3{
  margin-bottom: 0;
}
.set .center .left .item3 textarea{
  width: 100%;
  height: 120px;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px 15px ;
  border: none;
  resize: none;
  color: #333;
  font-family: initial;
}
.set .center .right{
  width: 240px;
  background-color: #f7f8f9;
  border: 1px dashed #d2d2d2;
  border-radius: 5px;
  margin-top: 40px;
}
.set .center .right .video-uploader{
  width: 100%;
  height: 100%;
}
.set .center .right .video-uploader .el-upload {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.set .center .right .video-uploader .el-upload  i{
  font-size: 42px;
  color: #999;
}
.set .center .right .video-uploader .el-upload .hit{
  color: #999;
  margin-top: 4px;
  font-size: 14px;
}
.bottom{
  width: 700px;
}
.bottom .item{
  margin: 10px 0;
}
.bottom .item>span{
  display: inline-block;
  width: 95px;
  color: #071140;
  font-weight: 400;
  font-size: 14px;
}
.bottom .item .el-input__inner{
  width: 220px;
  height: 36px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #dce2e9;
  color: #171f46;
}
.bottom .item .el-checkbox__label{
  font-size: 14px;
  color: #171f46;
}
.bottom .item .el-checkbox__label span{
  color: #666;
  font-size: 12px;
}
.bottom .item .el-input__prefix {
    top: -2px;
}
.publish-set{
  background-color: #f5f5f5;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 18px;
}
.publish-set .title{
  font-size: 14px;
  color: #282a3c;
  font-weight: bold
}
.publish-set .publish-set-form{
  margin-top: 20px;
}
.publish-set .publish-set-form .el-form-item {
  margin-bottom: 8px;
}
.publish-set .publish-set-form .el-form-item .el-input__inner{
  color: #171f46;
}
.publish-set .publish-set-form .el-form-item__label{
  color: #282a3c;
}
.publish-set .publish-set-form .el-checkbox__label{
  color: #071140;
}
.publish-set .publish-set-form .el-form-item .select-inp{
  width: 300px;
}
.publish-btn{
  width: 180px;
  height: 38px;
  background-color: #2f68ff;
  border-radius: 5px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  margin-top: 20px;
}
.publish-btn:hover{
  background-color: #2f68ff;
  color: #fff;
}
.publish-btn:focus {
  background-color: #2f68ff;
  color: #fff;
}
.oneKey .el-checkbox__input.is-checked .el-checkbox__inner,.oneKey .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #2F6BFF;
  border-color: #2F6BFF;
}

</style>