export function getUid() {
  return sessionStorage.getItem('uid')
}

export function setUid(uid) {
  return sessionStorage.setItem = ('uid',uid)
}

export function removeUid() {
  return sessionStorage.removeItem('uid')
}
