<template>
  <el-container>
    <el-aside class="home-aside">
      <Aside />
    </el-aside>
    <el-main class="home-c-main">
      <router-view></router-view>
    </el-main>
  </el-container>
</template>
<script>
  import Aside from '../components/aside.vue'
  export default {
    name: 'contentPage',
    components: { Aside }
  }
</script>
<style scoped>
  .home-aside {
    width: auto !important;
    height: calc(100vh - 140px);
    background-color: #fff;
  }

  .home-c-main {
    padding: 0;
    margin-left: 20px;
  }
</style>