<template>
  <div class="publish" v-loading="isLoading">
    <div class="top">
      <p class="top-title">发布管理</p>
      <div class="search">
        <el-form ref="publishForm" :model="publishForm" label-width="40px" class="publish-form">
          <el-form-item label="平台：">
            <el-select v-model="publishForm.platform" placeholder="请选择">
              <el-option v-for="item in platformOpt" :key="item.value" :label="item.label" :value="item.label">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="账户：">
            <el-select v-model="publishForm.account" placeholder="请选择">
              <el-option v-for="item in accountOpt" :key="item.value" :label="item.label" :value="item.label">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态：">
            <el-select v-model="publishForm.state" placeholder="请选择">
              <el-option v-for="item in stateOpt" :key="item.value" :label="item.label" :value="item.label">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间：" class="mar">
            <el-date-picker v-model="publishForm.time" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="名称：" class="mar">
            <el-input placeholder="请输入视频名称" v-model="publishForm.name"></el-input>
          </el-form-item>
          <el-form-item class="publish-form-btn mar" >
            <el-button class="search-btn btn" icon="el-icon-search">搜索</el-button>
            <el-button class="btn empty-btn" icon="el-icon-delete" @click="emptyHandle">清空条件</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content">
      <EmptyData :hitValue="hitValue" />
    </div>
  </div>
</template>
<script>
  import EmptyData from '../components/emptyData.vue'
  export default {
    name: 'publishPage',
    components: { EmptyData },
    data() {
      return {
        publishForm: {
          platform: '',
          account: '',
          state: '',
          time: '',
          name: '',
        },
        platformOpt: [
          {
            value: '1',
            label: '全部'
          },
        ],
        accountOpt: [
          {
            value: '1',
            label: '全部'
          },
        ],
        stateOpt: [
          {
            value: '1',
            label: '全部'
          },
        ],
        hitValue: '暂无数据',
        isLoading: true,
      }
    },
    methods: {
      emptyHandle() {
        this.publishForm.platform = ''
        this.publishForm.account = ''
        this.publishForm.state = ''
        this.publishForm.time = ''
        this.publishForm.name = ''
      }
    },
    mounted() {
      let that = this
      setTimeout(function () {
        that.isLoading = false
      }, 1000)
    },
  }
</script>
<style scoped>
  .top {
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px ;
  }

  .top .top-title {
    color: #111634;
    font-size: 24px;
    font-weight: bold;
  }

  .top .search {
    display: flex;
    align-items: center;
    margin-top: 18px;
  }
  .top .search>>> .el-date-editor .el-range__icon {
    margin-bottom: 4px;
  }
  .top .search>>> .el-range-separator{
    margin-bottom: 2px;
  }

  .top>>>.search .el-date-editor .el-range-input{
    color: #171f46;
  }
  .top .publish-form {
    display: flex;
    flex-wrap: wrap;
  }
  .top>>>.publish-form .mar{
    margin-bottom: 0;
  }
  .top>>>.publish-form .publish-form-btn .el-form-item__content {
    display: flex;
    align-items: center;
    margin-left: 0 !important;
  }

  .top>>>.publish-form .el-input__inner {
    width: 240px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #DCE2E9;
    border-radius: 8px;
    color: #171f46;
  }

  .top .el-form-item {
    margin-bottom: 18px;
    margin-right: 20px;
  }

  .top>>>.publish-form .el-form-item__label {
    color: #171f46;
    font-weight: 500;
    white-space: nowrap;
  }

  .el-date-editor--daterange.el-input__inner {
    width: 240px;
    height: 36px;
    border-radius: 8px;
    background-color: #fff;
    color: #8E98AF;
  }

  .top>>>.el-date-editor .el-range-separator {
    line-height: 30px;
    color: #8E98AF;
  }

  .top .btn {
    width: 110px;
    height: 36px;
    border-radius: 8px;
    font-size: 14px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .top .search-btn {
    color: #fff;
    background-color: #2f6bff;
  }

  .top .empty-btn {
    background-color: #fff;
    color: #171f46;
  }

  .content {
    margin-top: 20px;
    height: calc(100vh - 330px);
    background-color: #fff;
    overflow-y: auto;
    border-radius: 5px;
  }
</style>