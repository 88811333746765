
import axios from "axios"
axios.defaults.baseURL = 'https://sns.jinlaijinwang.net/dy_fast';
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers["Content-Type"] = "application/json";
        axios({
                url: url,
                method: "post",
                data: JSON.stringify(params),
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                if (err.response != undefined && err.response.status == 401) {
                    location.href = '/login'
                } 
                reject(err.data)
            })
    });
}
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(
                url, params = { params }
            )
            .then(res => {
                resolve(res);
            })
            .catch((err) => {
                if (err.response != undefined && err.response.status == 401) {
                    location.href = '/login'
                } 
                reject(err);
            })
    });
}

export function download(url, name) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: url,
            responseType: 'blob'
        }).then((res) => {
            if ('download' in document.createElement('a')) {
                let url = window.URL.createObjectURL(res.data);
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            } else {
                navigator.msSaveBlob(res.data, name)
            }
            window.close();
            resolve("ok");
        }).catch(err => {
            reject(err.data);
        })
    })
}



export default {get, post, download }