<template>
  <div class="flow" v-loading="isLoading">
    <div class="top">
      <p class="top-title">流量数据</p>
      <div class="search">
        <div>
          <span class="search-title">抖音账号：</span>
          <el-select v-model="selectStatus" placeholder="请选择" class="mat-sea" @change="selectStatusHandle">
            <el-option
              v-for="item in options"
              :key="item.accountId"
              :label="item.name"
              :value="{value:item.accountId,label:item.name}">
            </el-option>
          </el-select>
        </div>
        <div>
          <span class="search-title">统计时间：</span>
          <el-date-picker v-model="statisticsTime" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div>
          <el-button class="search-btn btn" icon="el-icon-search">搜索</el-button>
          <el-button class="btn empty-btn" icon="el-icon-delete" @click="emptyHandle">清空条件</el-button>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="left">
        <div class="photo">
          <img :src="infoData.head" alt="">
        </div>
        <div class="int">
          <p class="name">{{infoData.name}}<span v-if="infoData.tag==''  ">&nbsp;,&nbsp;{{infoData.tag}}</span></p>
          <p class="com">杭州思亿欧网络科技股份有限公司</p>
        </div>
      </div>
      <span class="rule"></span>
      <div class="right">
        <div>
          <span class="data-name">粉丝数</span>
          <div>
            <img src="../assets/img/flow1.png" alt="">
            <span class="data">{{infoData.fansNum}}</span>
          </div>
        </div>
        <div>
          <span class="data-name">点赞数</span>
          <div>
            <img src="../assets/img/flow2.png" alt="">
            <span class="data">{{infoData.likeNum}}</span>
          </div>
        </div>
        <div>
          <span class="data-name">评论数</span>
          <div>
            <img src="../assets/img/flow3.png" alt="">
            <span class="data">{{infoData.commentNum}}</span>
          </div>
        </div>
        <div>
          <span class="data-name">首页访问量</span>
          <div>
            <img src="../assets/img/flow4.png" alt="">
            <span class="data">{{infoData.viewNum}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="echarts-box">
      <p class="title">累计趋势图</p>
      <div id="myChart"></div>
    </div>
    <div class="bottom">
      <p class="title">近日数据对比</p>
      <div class="form-box">
        <div class="head">
          <div>
            <span>日期</span>
          </div>
          <div class="head-right">
            <div>
              <span>新增粉丝</span>
            </div>
            <div>
              <span>新增粉丝</span>
            </div>
            <div>
              <span>新增评论</span>
            </div>
            <div>
              <span>新增转发</span>
            </div>
            <div>
              <span>新增访客</span>
            </div>
          </div>
        </div>
        <div>
          <p>今天</p>
          <div class="item" v-for="(item,index) in today" :key="index">
            <p>
              <span class="num"> {{item.num}}</span>
              <span class="change" :class="item.isRise== true ? 'up':'descend' "> 
                <span class="circle">
                  <img src="../assets/img/icon1.png" alt="" class="xia">
                  <img src="../assets/img/icon2.png" alt="" class="sheng">
                </span>
                  <span>{{item.changeNum}}%</span></span>
            </p>
          </div>
        </div>
        <div>
          <p>昨天</p>
          <div class="item" v-for="(item,index) in yesterday" :key="index">
            <p>
              <span class="num"> {{item.num}}</span>
              <span class="change" :class="item.isRise== true ? 'up':'descend' "> 
                <span class="circle">
                  <img src="../assets/img/icon1.png" alt="" class="xia">
                  <img src="../assets/img/icon2.png" alt="" class="sheng">
                </span>
                <span>{{item.changeNum}}%</span> </span>
            </p>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'flowPage',
    data() {
      return {
        account: '',
        statisticsTime: '',
        tableData: [],
        infoData: {},
        echartsData: [],
        echartsTime: [],
        echartsNum: [],
        today: [],
        yesterday: [],
        isLoading: true,
        options:[

        ],
        selectStatus:'',
      }
    },
    methods: {
      draw() {
        let myChart = this.$echarts.init(document.getElementById('myChart'))
        var option = {
          tooltip: {
            // show:true,  //控制显示隐藏
            trigger: 'axis',
            axisPointer: {
              type: 'line',
              lineStyle: {
                color: 'rgba(0,0,0,0)',
                width: 2,
                type: 'solid'
              }
            },
            backgroundColor: 'rgba(27,27,27,0.8)',
            formatter: function (params) {
              let res = ""; //变量一个res
              let time = ''
              params.forEach(item => {
                res = item.data; //获取划过的数据
                time = item.axisValue
              })
              return `
          <div class="tooltip">
            <div class="tooltip-content">
              <p style='color:#fff'>${time}</p>
              <p style="color: #fff;"><span style="font-weight: bold;">${res}</span>&nbsp;个</p>
            </div>
          </div>
          `;
            },

          },
          xAxis: {
            type: 'category',
            axisLabel: {
              show: true,
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false,
            },
            data: this.echartsTime,
            nameLocation: "start",
            nameTextStyle: {
              align: "center",//文字水平对齐方式，默认自动
              verticalAlign: "top",//文字垂直对齐方式，默认自动
              lineHeight: 28,//行高
              fontSize: 10,
              color: "#eee"//字体颜色
            },
          },

          yAxis: {
            type: 'value',
            min: (value) => {
              return value.min
            },
            max: (value) => {
              return value.max
            },
            axisLabel: {
              formatter: this.formatter
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ['#eee'],
                width: 1,
                type: 'dashed'
              }
            }
          },
          grid: {
            x: 40,
            y: 30,
            y2: 20,
            x2: 40,
          },
          // 数据
          series: [
            {
              data: this.echartsNum,
              type: 'line',
              smooth: true,
              symbolSize: 6,
              symbol: 'circle',
              itemStyle: {
                normal: { color: '#29C828' }, //改变折线点的颜色
                lineStyle: { color: '#29C828', width: 3 } //改变折线颜色
              },
            }
          ]
        }
        //防止越界，重绘canvas，解决掉屏幕缩小，图表不会随之适应的缩小的方法
        window.onresize = myChart.resize;
        //设置option
        myChart.setOption(option);
      },
      emptyHandle() {
        this.account = ''
        this.statisticsTime = ''
      },
      accountID(uid){
        this.$get('/account/data', { account_id: uid }).then(res => {
          this.today = res.data.data.todayData
          this.yesterday = res.data.data.yesterdayData
          this.infoData = res.data.data.accData
          this.echartsData = res.data.data.statistic
          this.echartsData.forEach(item => {
            this.echartsTime.push(item.date)
            this.echartsNum.push(item.num)
          })
          this.draw()
        })
      },
      selectStatusHandle(){
        this.echartsTime=[]
        this.echartsNum=[]
        this.accountID(this.selectStatus.value)

      }
    },
    mounted() {
      let that = this
      setTimeout(function () {
        that.isLoading = false
      }, 1000)
        this.$get('/account/simi',{uid:1}).then((res)=>{
          this.options=res.data.data
          this.accountID(res.data.data[0].accountId)
        })
    },
  }
</script>
<style scoped>
  .top {
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
  }

  .top .top-title {
    color: #111634;
    font-size: 24px;
    font-weight: bold;
  }

  .top .search {
    display: flex;
    align-items: center;
    margin-top: 18px;
  }

  .top .search>div {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  .top .search>>> .el-date-editor .el-range__icon {
    margin-bottom: 4px;
  }
  .top .search>>> .el-range-separator{
    margin-bottom: 2px;
  }
  .top>>>.search .mat-sea input {
    width: 240px;
    height: 36px;
    background-color: #fff;
    border-radius: 8px;
    color: #171f46;
  }
  .top>>>.search .el-date-editor .el-range-input{
    color: #171f46;
  }
  .top .search-title {
    display: block;
    color: #171f46;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
  }

  .top .btn {
    width: 110px;
    height: 36px;
    border-radius: 8px;
    font-size: 14px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .top .search-btn {
    color: #fff;
    background-color: #2f6bff;
  }

  .top .empty-btn {
    background-color: #fff;
    color: #171f46;
  }

  .el-date-editor--daterange.el-input__inner {
    width: 240px;
    height: 36px;
    border-radius: 8px;
    background-color: #fff;
    color: #8E98AF;

  }

  .top>>>.el-date-editor .el-range-separator {
    line-height: 30px;
    color: #8E98AF;
  }

  .info {
    background-color: #fff;
    border-radius: 10px;
    height: 100px;
    box-sizing: border-box;
    padding: 25px 20px;
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  .info .left {
    display: flex;
    align-items: center;
    width: 300px;
  }

  .info .left .photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    overflow: hidden;
  }

  .info .left .photo img {
    max-width: 100%;
    border-radius: 50%;
  }

  .info .left .int .name {
    color: #171f46;
    font-weight: bold;
  }

  .info .left .int .com {
    color: #666;
    font-size: 12px;
    margin-top: 10px;
  }

  .info .rule {
    display: inline-block;
    width: 1px;
    height: 30px;
    background-color: #e5e5e5;
    border-radius: 1px;
  }

  .info .right {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 90px;
  }

  .info .right>div {
    width: 24%;
  }

  .info .right>div .data-name {
    color: #171f46;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 10px;
  }

  .info .right>div div {
    display: flex;
    align-items: center;
  }

  .info .right>div div img {
    margin-right: 6px;
  }

  .info .right>div .data {
    font-size: 20px;
    font-weight: bold;
    color: #171f46;
  }

  .echarts-box {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px;
  }
  .echarts-box .title{
    color: #171f46;
    font-weight: bold;
    margin-left: 20px;
  }
  #myChart {
    width: 100%;
    height: 280px;
  }

  .echarts-box #myChart>>>div:last-child {
    width: 104px !important;
    height: 95px !important;
    border-radius: 15px !important;
    box-sizing: border-box;
    padding: 20px 12px !important;
  }

  .echarts-box #myChart>>>div:last-child .tooltip {
    padding: 0px !important;
    width: 100% !important;
    height: 100% !important;

  }

  .echarts-box #myChart>>>div:last-child .tooltip-content {
    padding: 0px !important;
    width: 100% !important;
    height: 100% !important;
  }

  .echarts-box #myChart>>>div:last-child .tooltip-content p:last-child {
    font-size: 18px;
    margin-top: 6px;
  }

  .bottom {
    background-color: #fff;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 16px 20px;
    border-radius: 10px;
    width: 100%;
  }

  .bottom .title {
    color: #171f46;
    font-weight: bold;
    margin-left: 20px;
  }

  .bottom .form-box {
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
  }

  .bottom .form-box>div {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 20px;
  }

  .bottom .form-box>div>p {
    width: 100px;
    color: #171f46;
  }

  .bottom .form-box .item {
    width: 25%;
    box-sizing: border-box;
    padding: 0 20px;
    flex: 1;
    margin: 5px 0;
  }

  .bottom .form-box .item span {
    white-space: nowrap;
    color: #171f46;
  }
  .bottom .form-box .item .change.up .circle .xia{
    display: none;
  }
  .bottom .form-box .item .change.up .circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: inline-flex;
    margin: 0 6px;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  .bottom .form-box .item .change.up span:last-child {
    color: #ff4851;
    font-size: 12px;
  }

  .bottom .form-box .item .change.descend .circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    color: #14A768;
    display: inline-flex;
    margin: 0 6px;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .bottom .form-box .item .change.descend .circle .sheng{
    display: none;
  }
  .bottom .form-box .item .change.descend span:last-child {
    color: #14A768;
    font-size: 12px;
  }

  .bottom .form-box .head {
    display: flex;
    align-items: center;
    background-color: #f7f8f9;
    color: #8E98AF;
  }

  .bottom .form-box .head div:first-child {
    width: 100px;
  }

  .bottom .form-box .head .head-right {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .bottom .form-box .head .head-right div {
    width: 25%;
    box-sizing: border-box;
    padding: 5px 20px;
  }
</style>