<template>
  <div class="aside-box">
    <div class="fold">
      <el-button type="text" icon="el-icon-s-unfold" v-if="isCollapse" @click="isCollapse=!isCollapse"></el-button>
      <el-button type="text" icon="el-icon-s-fold" v-if="!isCollapse" @click="isCollapse=!isCollapse"></el-button>
    </div>
    <el-menu :default-active="$route.path" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse" router>
      <el-menu-item index="/admin/onekey" :router="{name:'adminOnekey'}" class="one" :class="isCollapse?'act':'' ">
        <i class="el-icon-s-promotion"></i>
        <span slot="title">企业号视频发布</span>
      </el-menu-item>
      <el-menu-item index="/admin/matrix" :router="{name:'adminMatrix'}" class="two sty">
        <!-- <i class="el-icon-s-custom"></i> -->
        <img src="../assets/img/aside1.png" alt="" class="bai">
        <img src="../assets/img/aside1-1.png" alt="" class="lan">
        <span slot="title">
          员工矩阵 
          <img src="../assets/img/aside4.png" alt=""  class="bai" v-if="!isCollapse">
          <img src="../assets/img/aside4-1.png" alt=""  class="lan" v-if="!isCollapse">
        </span>
      </el-menu-item>
      <el-menu-item index="/admin/flow" :router="{name:'adminFlow'}" class="sty">
        <!-- <i class="el-icon-s-marketing"></i> -->
        <img src="../assets/img/aside3.png" alt="" class="bai">
        <img src="../assets/img/aside3-1.png" alt="" class="lan">
        <span slot="title">
          流量数据 
          <img src="../assets/img/aside4.png" alt=""  class="bai" v-if="!isCollapse">
          <img src="../assets/img/aside4-1.png" alt=""  class="lan" v-if="!isCollapse">
        </span>
      </el-menu-item>
      <el-menu-item index="/admin/publish" :router="{name:'adminPublish'}" class="sty">
        <!-- <i class="el-icon-s-finance"></i> -->
        <img src="../assets/img/aside2.png" alt="" class="bai">
        <img src="../assets/img/aside2-1.png" alt="" class="lan">
        <span slot="title">
          发布管理 
          <img src="../assets/img/aside4.png" alt=""  class="bai" v-if="!isCollapse">
          <img src="../assets/img/aside4-1.png" alt=""  class="lan" v-if="!isCollapse">
        </span>
      </el-menu-item>
      <el-menu-item index="/admin/private" :router="{name:'adminPrivate'}" class="sty">
        <!-- <i class="el-icon-s-finance"></i> -->
        <img src="../assets/img/aside2.png" alt="" class="bai">
        <img src="../assets/img/aside2-1.png" alt="" class="lan">
        <span slot="title">
          私信管理 
          <img src="../assets/img/aside4.png" alt=""  class="bai" v-if="!isCollapse">
          <img src="../assets/img/aside4-1.png" alt=""  class="lan" v-if="!isCollapse">
        </span>
      </el-menu-item>
    </el-menu>
  </div>
  
</template>
<script>
  export default{
    name:'asidePage',
    data() {
      return {
        activeIndex: '1',
        isCollapse:false,
      }
    },
    methods: {
    handleOpen() {
    },
    handleClose() {
    },
    handleSelect(keyPath) {
      sessionStorage.setItem('keyPath', keyPath);
    }
  },
  mounted() {
    this.activeIndex = sessionStorage.getItem('keyPath') || '1';
  }

  }
</script>
<style>
  .aside-box{
    background-color: #fff;
    box-sizing: border-box;
  }
  .aside-box .fold {
    margin-top: 20px;
  }
  .aside-box .fold .el-button{
    font-size: 30px;
    color: #171F46;
    margin-left: 20px;
  }
  .aside-box .el-menu .el-menu-item{
    padding: 0 40px !important;
    box-sizing: content-box;
  }
  .aside-box .el-menu .el-menu-item span{
    margin-left: 6px;
  }
  .aside-box .el-menu .el-menu-item .lan{
    display: none;
  }
  .aside-box .el-menu .el-menu-item.is-active .lan{
    display: inline-block;
  }
  .aside-box .el-menu .el-menu-item.is-active .bai{
    display: none;
  }

  .aside-box .el-menu .one{
    background-color: #2f6bff;
    height: 40px;
    border-radius: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 40px;
    margin:0 20px;
    margin-bottom: 16px;
    box-sizing: border-box;
  }
  .aside-box .el-menu .one.act{
    margin: 0;
    margin-bottom: 16px;
    padding: 0 20px !important;
  }
  .aside-box .el-menu .one:hover{
    background-color: #2f6bff;
  }
  .aside-box .el-menu .el-menu-item.sty span{
    display: inline-flex;
    width: 120px;
    align-items: center;
    justify-content: space-between;
  }
  .aside-box .el-menu .one i{
    color: #fff;
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 220px;
    min-height: 400px;
  }
  .aside-box .el-menu-item{
    color: #333;
    font-weight: bold;
  }
  .aside-box .el-menu-item.is-active {
    color: #2F6BFF;
  }
  .aside-box .el-menu-item.is-active i{
    color: #2F6BFF;

  }
  .aside-box .el-menu .one.is-active{
    color: #fff;

  }
  .aside-box .el-menu .one.is-active i{
    
    color: #fff;
  }

  .el-menu-vertical-demo{
    height: 100%;
  }
  .definition{
    height: 106px;
    border-bottom: 1px solid #F1F5F8;

  }
  .definition .el-button{
    color: #111634;
    font-size: 20px;
  }
  .definition .el-menu-item{
    width: 200px;
    border-radius: 5px;
    height: 40px;
    margin-top: 10px;
    background-color: #2f6bff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-menu-vertical-demo {
    border-right: none;
  }
</style>