<template>
  <div class="empty-data">
    <div class="empty">
      <img src="../assets/img/empty.png" alt="">
      <p v-html="hitValue"></p>
    </div>
  </div>
</template>
<script>
  export default{
    props:['hitValue'],
    name:'emptyData'
  }
</script>
<style scoped>
  .empty-data{
    position: relative;
    width: 100%;
    height: 90%;
  }
  .empty-data .empty{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .empty-data .empty p{
    font-size: 14px;
    color: #999;
    margin-top: 20px;
    text-align: center;
  }
</style>